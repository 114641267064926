<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                世纪证券携手非凸科技，多元探讨量化发展及生态合作
              </p>
              <div class="fst-italic mb-2">2023年12月7日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >世纪证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    为促进量化行业高质量发展蓄势赋能，11月23日，世纪证券携手非凸科技在上海开展“科技而生·赋能量化”闭门沙龙活动，就当下量化发展及生态合作展开了多元探讨。艾悉资产、竹润投资、三亚澜和、歌斐资产、世纪资管、山金期货资管等资方、管理人应邀参加。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/02-乔伟.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为国内的综合性金融机构，世纪证券一直以开放合作的态度，充分利用股东优势、区位优势和政策优势，积极营造前海金融生态，推动金融科技与实体经济深度融合，为行业的稳步发展贡献自己的力量。近年来，世纪证券资产管理业务发挥团队优势，打造以固收、“固收+”为“核心”，以权益、FOF和资产证券化为“卫星”的“核心+卫星”精品券商服务体系。世纪证券资产管理部副总经理乔伟表示，世纪资管以客户服务为着力点，构建资管业务核心竞争优势。通过转变产品销售模式，强化管理服务支撑，推动科技赋能，实现有温度的全天候管家式金融服务。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/03-余昊璋.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    世纪证券资产管理部余昊璋从“多元策略、管理人优选、配置为王、风险导向、科技赋能”纬度详细介绍了SMART资产配置体系。FOF产品方面，世纪证券引入各类资产配置专家，将国外先进投研理念和系统与本土相结合，通过综合“定量+定性”的研究体系，自上而下研判宏观周期并筛选大类资产，自下而上挑选投资标的，并凭借丰富的组合管理经验和有效的动态调整机制，为投资者创造长期稳健的持续收益。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/04-艾悉.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    艾悉资产量化研究总监李烨则聚焦量化类产品，分享了投资策略和投研体系，其投资策略主要包括量化CTA、基本面量化、波动率量化等。同时，强调了量化策略在资产配置中的核心要点是风险控制，要始终坚持策略创新和研发，为客户创造持续稳健的绝对收益。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/05-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技机构业务经理周升分享了量化投研体系及智能交易解决方案。非凸科技聚焦中国金融市场，以Rust全栈技术、AI算法力、金融理解力等为核心驱动，构建新一代全业务智能交易平台，为客户提供一站式智能交易领域服务解决方案。伴随经济周期的自然波动和技术发展的一波波浪潮，非凸科技始终坚持在正确的赛道上，保持量化投研竞争力，并跟上技术创新发展趋势，全方位助力投资者获得长期稳定的收益。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/06-竹润.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    竹润投资是一家专注于“固收+”多元复合策略的证券类私募基金管理人，长期聚焦债券市场，对各类债券投资均有深入的研究。竹润投资量化负责人戈文韬表示，把客户利益放在第一位，是竹润一贯坚守的理念。追求绝对收益的同时兼顾相对收益，给投资者带来长期复利收益。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/07-澜和.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，三亚澜和、歌斐资产、山金期货资管也分别分享了自身量化策略优势及对量化投研的思考，并与在场嘉宾共同探讨如何实现量化行业的可持续发展与生态伙伴的合作共赢。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/63/08-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技与世纪证券将在技术融合及投入、联合解决方案的打造与市场拓展、服务合作等方面展开全面合作。非凸科技也将始终与行业生态伙伴一起，在高质量发展的道路上携手前行。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News63",
};
</script>

<style></style>
